import SloudEmblem from '../../assets/images/sloud-emblem.svg'
import LoaderStyles from './Loader.module.scss'

const Loader = (): JSX.Element => {
  return (
    <div className={LoaderStyles.loader}>
      <svg
        className={LoaderStyles.loader__spinner}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="50"
        height="50"
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          stroke="#ddd"
          strokeWidth="3"
          fill="none"
        />
        <circle
          cx="25"
          cy="25"
          r="20"
          stroke="#F20788"
          strokeWidth="3"
          fill="none"
          strokeDasharray="125.6"
          strokeDashoffset="125.6"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="125.6;0"
            keyTimes="0;1"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>

      <img src={SloudEmblem} alt="Logo" className={LoaderStyles.loader__logo} />
    </div>
  )
}

export default Loader
