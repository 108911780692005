import { apiService } from '../index'
import {
  type IFormattedAnalyticsAggregatesResponse,
  type IAnalyticsAggregatesResponse,
  type IStoreMetricsResponse,
  type IAnalyticsGraphResponse,
  type IFormattedAnalyticsGraphResponse
} from './metricsAPI.types'

export const metricsAPISlice = apiService
  .enhanceEndpoints({
    addTagTypes: ['Metrics']
  })
  .injectEndpoints({
    endpoints: build => ({
      fetchStoreMetrics: build.query<IStoreMetricsResponse, null>({
        query: () => ({
          method: 'GET',
          url: 'api/v1/stores/metrics'
        })
      }),
      fetchAnalyticsAggregates: build.query<
        IFormattedAnalyticsAggregatesResponse,
        null
      >({
        query: () => ({
          method: 'GET',
          url: 'api/v1/analytics/aggregate'
        }),
        providesTags: ['Metrics'],
        transformResponse: (response: IAnalyticsAggregatesResponse) => {
          return {
            status: response.status,
            message: response.message,
            data: {
              recentOrders: response.data.recent_orders.map(order => ({
                id: order.id,
                name: order.products,
                price: order.amount,
                status: order.status
              })),
              bestSellers: response.data.best_sellers.map((seller, i) => ({
                id: i.toString(),
                name: seller.product_name,
                price: seller.product_price
              })),
              topCustomers: response.data.top_customers.map(customer => ({
                id: customer.customer_id,
                name: `${customer.customer_first_name} ${customer.customer_last_name}`,
                price: Number(customer.total_amount)
              })),
              topCategories: response.data.top_categories.map(
                (category, i) => ({
                  id: i.toString(),
                  name: category.product_category,
                  value: Number(category.count)
                })
              ),
              topLocations: response.data.top_locations.map((category, i) => ({
                id: i.toString(),
                name: category.city,
                value: Number(category.count)
              }))
            }
          }
        }
      }),
      fetchAnalyticsGraph: build.query<IFormattedAnalyticsGraphResponse, null>({
        query: () => ({
          method: 'GET',
          url: 'api/v1/analytics/graph'
        }),
        transformResponse: (response: IAnalyticsGraphResponse) => {
          return {
            status: response.status,
            message: response.message,
            data: response.data
              .map(dataPoint => ({
                day: dataPoint.weekday,
                dayAbbrev: dataPoint.weekday,
                amount: Number(dataPoint.total_sales)
              }))
              .reverse()
          }
        }
      })
    })
  })

export const {
  useFetchStoreMetricsQuery,
  useFetchAnalyticsAggregatesQuery,
  useFetchAnalyticsGraphQuery
} = metricsAPISlice
