import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface ITransferData {
  account_number: string
  account_name: string
  bank_code: string
  bank: string
  amount: string
  remark: string
  is_beneficiary: boolean
  fee: string
  total: string
}

interface IReceiptData {
  type: 'debit' | 'credit'
  amount: string | number
  reference: string
  details: Array<{
    id: number
    title: string
    value: string | number
  }>
}

interface IWalletSlice {
  isShowWalletModal: boolean
  activeWalletModal:
    | 'transferFormModal'
    | 'transferConfirmModal'
    | 'transferBenModal'
    | 'transferReceiptModal'
    | 'transferPINModal'
    | ''
  transferData: ITransferData
  isShowSaveBeneficiary: boolean
  receiptData: IReceiptData
  businessConfig: { kind: string; name: string; capped: number; figure: number }
}

const initialState: IWalletSlice = {
  isShowWalletModal: false,
  activeWalletModal: '',
  transferData: {
    account_number: '',
    account_name: '',
    bank_code: '',
    bank: '',
    amount: '',
    remark: '',
    is_beneficiary: false,
    fee: '',
    total: ''
  },
  isShowSaveBeneficiary: true,
  receiptData: {
    type: 'debit',
    amount: '0',
    reference: '',
    details: []
  },
  businessConfig: {
    kind: '',
    name: '',
    capped: 0,
    figure: 0
  }
}

export const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {
    setIsShowWalletModal: (
      state: IWalletSlice,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action
      state.isShowWalletModal = payload
    },
    setActiveWalletModal: (
      state: IWalletSlice,
      action: PayloadAction<
        | 'transferFormModal'
        | 'transferConfirmModal'
        | 'transferBenModal'
        | 'transferReceiptModal'
        | 'transferPINModal'
        | ''
      >
    ) => {
      const { payload } = action
      state.activeWalletModal = payload
    },
    setTransferData: (
      state: IWalletSlice,
      action: PayloadAction<ITransferData>
    ) => {
      const { payload } = action
      state.transferData = payload
    },
    setIsShowSaveBeneficiary: (
      state: IWalletSlice,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action
      state.isShowSaveBeneficiary = payload
    },
    setReceiptData: (
      state: IWalletSlice,
      action: PayloadAction<IReceiptData>
    ) => {
      const { payload } = action
      state.receiptData = payload
    },
    setBusinessConfig: (
      state: IWalletSlice,
      action: PayloadAction<{
        kind: string
        name: string
        capped: number
        figure: number
      }>
    ) => {
      const { payload } = action
      state.businessConfig = payload
    }
  }
})

const { actions, reducer } = walletSlice
export const {
  setIsShowWalletModal,
  setActiveWalletModal,
  setTransferData,
  setIsShowSaveBeneficiary,
  setReceiptData,
  setBusinessConfig
} = actions
export default reducer
